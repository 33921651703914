.header {
  background-color: #9747b6;
  max-height: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 1vh 1vw;
}

.header-title {
  font-size: calc(8px + 2vmin);
  font-weight: bold;
  width: 1400px;
}

.header-subtext {
  font-size: 10px;
  width: 1400px;
}

.input-wrapper {
  height: 300px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input-area {
  width: 1400px;
  height: 85%;
  resize: none;
}

.svg-wrapper {
  width: 100vw;
  margin-top: 1vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Graph css */

svg {
  border: 1px solid #999;
  overflow: hidden;
}

.node {
  white-space: nowrap;
}

.node rect,
.node circle,
.node ellipse {
  stroke: #333;
  fill: #f5f5f5;
  stroke-width: 1.5px;
}

.node div.type {
  font-weight: bold;
  font-size: 0.6em;
}

.node.StartNode div, .node.StopNode div {
  color: #fff;
}

.node.StartNode rect, .node.StopNode rect  {
  fill: #000;
}

.cluster rect {
  stroke: #333;
  fill: #000;
  fill-opacity: 0.1;
  stroke-width: 1.5px;
}

.edgePath path.path {
  stroke: #333;
  stroke-width: 1.5px;
  fill: none;
}
